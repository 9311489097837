<template>
  <div class="advantage">
      <div class="advantage-title">为什么选择我们</div>
      <div class="advantage-content">
          <div v-for="(item,index) in list" :key="index" class="advantage-content-item">
              <img :src="item.src" :alt="item.title">
              <p>{{item.title}}</p>
          </div>

      </div>

  </div>
</template>

<script>
import * as img1 from 'img/advantage/img1.png';
import * as img2 from 'img/advantage/img2.png';
import * as img3 from 'img/advantage/img3.png';
export default {
  data () {
    return {
        list:[
            {
                title:'专业的技术团队',
                src:img1

            },
            {
                title:'强大的售后保障',
                src:img2

            },
            {
                title:'有力的品牌保障',
                src:img3

            },
            
        ]
    };
  },
  components: {},
  computed: {},
  methods: {}
}
</script>
<style lang='scss' scoped>
.advantage{
    height: 685px;
    width:100%;
    background-image: url(~img/advantage/bg.png);
    background-size: 100% 100%;
    opacity: 0;
    &-title{
        font-weight: 900;
        font-size: 32px;
        text-align: center;
        padding:80px 0;
        color: #FFFFFF;
    }
    &-content {
        display: flex;
        justify-content: space-between;
        width: 1200px;
        margin:0 auto;
        &-item {
            width:370px;
            height:380px;
            border-radius: 10px;
            background:#fff;
            img{
                vertical-align: bottom;
            }
            p{
                padding:33px 100px;
                box-sizing: border-box;
                font-weight: bold;
                font-size: 24px;
                color: #142037;
                text-align: center;
            }
        }
    }
}
  .animated{
      opacity: 1;
  }
</style>