<template>
    <div class="index">
        <img src="../../assets/images/activity/618/Slice38.png" />
        <img src="../../assets/images/activity/618/Slice39.png" @click="handleButton"/>
        <a href="https://ewei.shop/activity/618" target="_blank"><img src="../../assets/images/activity/618/Slice40.png" /></a>
        <Tryout />
    </div>
</template>
<script>
import Tryout from "../index/components/tryout";
export default {
    data() {
        return {
            isTab:true
        };
    },
    metaInfo: {
        title: '618多版本超值嗨购，钜惠狂欢！', // set a title
    },
    components: {Tryout},
    computed: {},
    methods: {
    },
};
</script>
<style lang='scss' scoped>
.index {
    font-size: 0;
    position: absolute;
    width: 100%;
    z-index:5000;
    background:#2717C6;
    img{
        width: 100%;
        display: block;
        margin: 0 auto;
    }
}


</style>
