<template>
    <div class="index" id="home">
        <!-- 轮播图 -->
        <div class="swiper">
            <swiper ref="mySwiper" :options="swiperOptions">
<!--                <swiper-slide>-->
<!--                    <div class="index-swiper" >-->
<!--                        <div class="left">-->
<!--                            <h2 class="title">云起链客SCRM私域流量运营神器</h2>-->
<!--                            <p class="des">-->
<!--                                <span>低成本营销</span>-->
<!--                                <span>快速获客</span>-->
<!--                                <span>提高客户转化</span>-->
<!--                            </p>-->
<!--                            <button class="all" @click="handleButton">了解更多</button>-->
<!--                        </div>-->
<!--                        <swiper ref="mySwiper" :options="swiperOptions" style="width: 676px;margin-top: 74px;margin-left: 134px;">-->
                            <swiper-slide v-for="(item, index) in list" :key="index">
                                <div class="swiper-item">
                                    <img class="show-img" :style="item.link?'cursor:pointer':''" :src="item.img" alt="" @click="goLink(item.link)" />
                                </div>
                            </swiper-slide>
                            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
<!--                        </swiper>-->
<!--                    </div>-->
<!--                </swiper-slide>-->
                <!-- <swiper-slide>
                    <a href="http://ewei.shop/activity/scrm" target="_blank">
                        <img :src="activeImg" alt="" class="active-img">
                    </a>
                </swiper-slide> -->
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
        <!-- 最新公告 -->
        <Notice />
        <!-- 专业的私域流量运营工具 -->
        <Utils />
        <!-- 客户管理 -->
        <CustomerManage id="customer" />
        <!-- 优势 -->
        <Advantage />
        <!-- 介绍 -->
        <Introduce />
        <!-- 加入我们 -->
        <JoinUs id="about" />
        <!-- 试用 -->
        <Tryout />
    </div>
</template>

<script>
import Notice from "./components/notice";
import Utils from "./components/utils";
import CustomerManage from "./components/customer-manage";
import Advantage from "./components/advantage";
import Introduce from "./components/introduce";
import JoinUs from "./components/joinUs";
import Tryout from "./components/tryout";
var preD = function (e) {
    e.preventDefault()
}
export default {
    name: "Index",
    components: {
        Notice,
        Utils,
        CustomerManage,
        Advantage,
        Introduce,
        JoinUs,
        Tryout,
    },
    data() {
        return {
            swiperOptions: {
                pagination: {
                    el: ".swiper-pagination",
                },
                autoplay: {
                    delay: 3000,
                    stopOnLastSlide: false,
                    disableOnInteraction: true
                },
                loop: true,
            },
            list: [{
                    img:require("../../assets/images/swiper/banner1.png"),
                    link:'sever'
                },{
                    img:require("../../assets/images/swiper/banner2.png"),
                    link:'sever'
                },{
                    img:require("../../assets/images/swiper/banner3.png"),
                    link:'sever'
                },
                
            ],
            activeImg:require("../../assets/images/swiper/active.png"),
        };
    },
    created() {
    },
    methods:{
        goLink(link){
            if(link == 'sever'){
                this.handleButton()
            }else if(link) {
                this.$router.push({
                    path: link
                })
            }
        }
    }
};
</script>

<style lang="scss">
.swiper-pagination-bullet-active {
    background: #fff !important;
}
.swiper-pagination .swiper-pagination-bullet {
    width: 36px;
    height: 6px;
    border-radius: 0;
    background: rgba(255, 255, 255, 0.4);
}
.swiper{
//background: url("../../assets/images/swiper/img_banner.png") no-repeat;
background-size: 100% 100%;
    .show-img {
        width: 100%;
    }
}
.index-swiper {
    width:1200px;
    margin:0 auto;
    position: relative;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    .left {
                margin-top: 90px;
                width: 500px;
        .title {
            font-size: 30px;
            line-height: 42px;
            font-weight: 900;
            color: #fff;
            margin-bottom: 20px;
        }
        .des {
            margin-bottom: 60px;
            font-size: 18px;
            line-height: 32px;
            color: #fff;
            span{
                margin-right: 20px;
            }
        }
        .all {
            width: 180px;
            height: 60px;
            font-size: 18px;
            line-height: 32px;
            color: #142037;
            font-weight: bold;
            border-radius: 100px;
            border: none;
            outline: none;
            text-align: center;
            background: #fff;
            cursor: pointer;
        }
    }
    .show-img {
        width: 620px;
        height: 433px;
    }
}
.active-img{
        width: 100%;
        height: 592px;
        display: block;
    }
    .anniversary{
        position: fixed;
        top: 0;
        right: 0;
        z-index: 1000;
        width: 200vh;
        height: 100vh;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        .del{
            position: absolute;
            left: 50%;
            transform: translate(350px,-220px);
        }
    }
</style>
