<template>
  <div class="join">
      <p class="join-title">
          <span style="margin-right:40px">加入我们</span>
          <span>立享优惠</span>
      </p>
      <div class="join-button">
         <span @click="handleButton">马上体验</span>
      </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },
  components: {},
  computed: {},
  methods: {}
}
</script>
<style lang='scss' scoped>
.join{
    width:100%;
    background-image: url(~img/join/bg.png);
    background-size: 100% 100%;
    padding:90px 0 70px 0;
    opacity: 0;
    &-title{
        text-align: center;
        color: #fff;
        padding-bottom:40px;
        font-weight: 900;
        font-size: 40px;
    }
    &-button{
        text-align: center;
        span{
            display:inline-block;
            width: 200px;
            height: 64px;
            background: #FFFFFF;
            border-radius: 100px;
            color: #3860F4;
            line-height: 64px;
            text-align: center;
            font-weight: 900;
            font-size: 24px;
            cursor: pointer;
        }
    }
}
  .animated{
      opacity: 1;
  }
</style>