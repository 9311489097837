<template>
    <div class="footer">
        <div>
            <div class="footer-left">
                <div class="footer-left-menu">
                   <span v-for="(item, index) in list" :key="index" @click="handleLink(item.link)">
                       {{item.title}}
                   </span>
                </div>
                <div class="footer-left-company">
                    青岛易联凤巢网络科技有限公司版权所有©2019-2021鲁ICP备18044507号
                </div>
            </div>
            <div class="footer-right">
                <div>
                    <img src="~img/index/code1.png" alt="" />
                    <p>EWEISHOP公众号</p>
                </div>
                <div>
                    <img src="~img/index/code2.png" alt="" />
                    <p>扫一扫了解更多</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            list:[
                {
                title:'首页',
                link:'#home'
          },
          {
                title:'功能介绍',
                link:'#customer'
          },
          {
                title:'关于我们',
                link:'#about'
          },
          {
                title:'帮助中心',
                link:'https://support.qq.com/products/311257/faqs-more/#parentId=52227?#classifyId=null'
          },
          {
                title:'友情链接',
                link:'http://ewei.shop/'
          }
            ]
        };
    },
    components: {},
    computed: {},
    methods: {},
};
</script>
<style lang='scss' scoped>
.footer {
    background: #0e1421;
    padding: 75px 0 25px 0;
    & > div {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }
    &-left {
        &-menu {
            display: flex;
            padding-bottom: 36px;
            span {
                color: #fff;
                margin-right: 40px;
                cursor: pointer;
            }
        }
        &-company {
            color: #a8a7a8;
        }
    }
    &-right {
        display: flex;
        margin-top:-35px;
        padding-bottom:10px;
        &>div:nth-child(1){
            margin-right:38px
        }
        img {
            width: 120px;
            height: 120px;
            padding-bottom: 20px;
        }
        p {
            text-align: center;
            font-weight: bold;
            font-size: 14px;
            color: #ffffff;
        }
    }
}
</style>