import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/reset.css'
import '@/assets/css/animate.min.css'
import MetaInfo from 'vue-meta-info'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import $ from "jquery"
/* 全局混入 */ 
import Mixin from './components/mixins';
Vue.mixin(Mixin);
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false
Vue.use(MetaInfo)
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
