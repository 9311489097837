<template>
    <div>
        <div class="customer-manage" v-for="(item,index) in tabs" :key="index" :class="[`customer${index+1}`,{'customer-left':isLeft(index+1)}]">
            <div class="box">
            <h2 class="title">{{ item.title }}</h2>
            <div class="content">
                <ul class="tabs"  :style="{paddingTop:item.content.length ==2 ? '120px': '26px'}">
                    <li
                        v-for="(item2, index2) in item.content"
                        :key="index2"
                        class="tab customer-item"
                        :class="{ 'active-tab':  index2 === item.active }"
                        :style="{backgroundImage:'url(' + item2.bg  + ')',backgroundSize:index2 === item.active?'100% 100%' :'0',}"
                        @mouseenter="handleMouseEnter(item,index2)"
                    >
                        <p class="tab-title">{{ item2.title }}</p>
                        <p class="tab-des">{{ item2.des }}</p>
                    </li>
                </ul>
                 <img class="show-img"  
                 :src="item2.img" alt=""  
                 v-for="(item2, index2) in item.content"
                  :key="index2" 
                  :style="{
                      opacity:index2 === item.active || item.isOnly? 1 : 0,
                      position:index2 === item.active? 'unset':'absolute'}">
               
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as bg1 from 'img/customer-manage/bg1.png';
import * as bg2 from 'img/customer-manage/bg2.png';
import * as bg3 from 'img/customer-manage/bg3.png';
import * as bg4 from 'img/customer-manage/bg4.png';
import * as bg5 from 'img/customer-manage/bg5.png';
import * as bg6 from 'img/customer-manage/bg6.png';
import * as bg7 from 'img/customer-manage/bg7.png';
import * as bg8 from 'img/customer-manage/bg8.png';
import * as bg9 from 'img/customer-manage/bg9.png';
import * as img1 from 'img/customer-manage/img1.png';
import * as img2 from 'img/customer-manage/img2.png';
import * as img3 from 'img/customer-manage/img3.png';
import * as img4 from 'img/customer-manage/img4.png';
import * as img5 from 'img/customer-manage/img5.png';
import * as img6 from 'img/customer-manage/img6.png';
import * as img7 from 'img/customer-manage/img7.png';
import * as img8 from 'img/customer-manage/img8.png';
export default {
  name: 'Index',
  data() {
    return {
      tabs: [
        { 
            title:'客户管理，强力触达客户需求',
            content:[
                { title: '完善客户画像', des: '完善客户画像，全面记录客户数据，洞察客户真实需求，实现与客户的有效沟通', bg: bg1,img:img1 },
                { title: '客户行为追踪', des: '客户的行为路径实时同步，销售跟进记录一目了然，利于客户行为分析，方便寻找潜在客户', bg: bg2,img:img2 },
                { title: '渠道化管理', des: '可分渠道管理客户，并根据不同的标签进行精准化客情维系与运营，提高客资转化率', bg:bg3,img:img3}
            ],
            active:0
        },
        {   
            title:'引流获客，实现客户指数级增长',
            content: [ 
                { title: '裂变获客', des: '突破私域引流的瓶颈，呼朋唤友领取奖励，低成本实现客户持续裂变式增长', bg:bg4,img:img4},
                { title: '渠道活码', des: '智能化活码随机分配员工，分渠道管理客户，并可对客服人员进行排班管理', bg: bg5,img:img5}
            ],
            active:0
        },
        {   
            title:'精细化社群运营',
            content: [ 
                { title: '群发助手', des: '支持给客户、客户群和企业成员群发消息，并可精准筛选用户，提升服务质量', bg:bg6,img:img6},
                { title: '话术素材', des: '建立企业标准的话术素材库，话术一键发送，让员工回复更加专业，让销售效率飞起来', bg: bg7,img:img7}
            ],
            active:0
        },
        {   
            title:'内部管理，客户资源不流失',
            content: [ 
                { title: '离职继承', des: '员工离职可一键继承客户资源，客户无感知交接，企业资产不流失', bg:bg8,img:img8},
                { title: '跟进提醒', des: '及时掌握和监督销售跟进情况，可设置跟进提醒，合理安排进度，把握节奏', bg: bg9,img:img8}
            ],
            active:0,
            isOnly:true
        }
      ],
    }
  },
  computed:{
        isLeft(){
            return (n)=>n%2 ==0;
        }
  },
  methods: {
      handleMouseEnter(item,index2){
          item.active = index2;
      }
  },
}
</script>

<style lang="scss">
  .customer-manage {
    opacity: 0;
    padding: 80px 0;
    background: #F7FBFF;
    .box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 32px;
        line-height: 45px;
        font-weight: 900;
        color: #142037;
        margin-bottom: 80px;
      }
      .content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .tabs {
                padding-top: 46px;
          .tab {
            width: 450px;
            padding: 55px;
            border-radius: 10px;
            background-size: 100% 100%;
            box-sizing: border-box;
            cursor: pointer;
                margin-top: -35px;
            &.active-tab {
              color: #fff;
              // background: #3860F4;
            }
            .tab-title {
              font-size: 20px;
              line-height: 28px;
              font-weight: bold;
              margin-bottom: 12px;
            }
            .tab-des {
              font-size: 16px;
              line-height: 26px;
            }
          }
        }
        .show-img {
          width: 680px;
          height: 540px;
          top: -1000px;
          transition: all 1s;
          margin: -35px;
        }
      }
    }
  }
  .customer-left{
      background:#fff;
      .content{
          flex-flow: row-reverse;
      }
  }
  .animated{
      opacity: 1;
  }
</style>
