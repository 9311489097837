<template>
  <div :class="['header',{'headerTop':headerTop}]" :style="style" >
    <div class="box">
      <img class="logo" src="../../assets/images/index/logo.png" v-if="!headerTop"/>
       <img class="logo" src="../../assets/images/index/scrollLogo.png" v-else/>
      <div class="right">
        <ul class="nav-list">
          <li v-for="(item, index) in nav" :key="index"  @click="handleLink(item.link)" class="nav-item">
              {{item.title}}
          </li>
        </ul>
        <button class="now" @click="handleButton">立即体验</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      nav: [
          {
                title:'首页',
                link:'#home'
          },
          {
                title:'功能介绍',
                link:'#customer'
          },
          {
                title:'关于我们',
                link:'#about'
          },
          {
                title:'帮助中心',
                link:'https://support.qq.com/products/311257/faqs-more/#parentId=52227?#classifyId=null',
                target:'_blank'
          }
      ],
      style: {
        background: 'transparent',
      },
      headerTop:true
    }
  },
  mounted() {
      this.windowScroll()
        window.addEventListener("scroll", this.windowScroll); //监听页面滚动
    },
    methods: {
        windowScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            this.opacity = Math.abs(Math.round(scrollTop)) / 250;
            this.style = {background: `rgba(255, 255, 255,${this.opacity})`}
            if(scrollTop>100){
                this.headerTop = true
            }else{
                this.headerTop = false
            }
            if(scrollTop>=0) { 
                $(".utils-item").addClass("fadeInUp animated")
            }
            if(scrollTop>=900) { 
                $(".customer1").addClass("fadeInUp animated")
            }
            if(scrollTop>=1800) { 
                $(".customer2").addClass("fadeInUp animated")
            }
            if(scrollTop>=2500) { 
                $(".customer3").addClass("fadeInUp animated")
            }
            if(scrollTop>=3300) { 
                $(".customer4").addClass("fadeInUp animated")
            }
            if(scrollTop>=4000) { 
                $(".advantage").addClass("fadeInUp animated")
            }
             if(scrollTop>=4600) { 
                $(".introduce").addClass("fadeInUp animated")
            }
             if(scrollTop>=5200) { 
                $(".join").addClass("fadeInUp animated")
            }
            
        },
    },
    destroyed() {
        window.removeEventListener("scroll", this.windowScroll); //销毁滚动事件
    }
}
</script>

<style lang="scss">
  .header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 80px;
    .box {
      width: 1200px;
      height: 80px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        max-width: 295px;
      }
      .right {
        display: flex;
        .nav-list {
          display: flex;
          align-items: center;
          .nav-item {
            font-size: 16px;
            color: #fff;
            margin-right: 80px;
            cursor: pointer;
          }
          .isTop{
              color: #333333;
          }
        }
        .now {
          width: 140px;
          height: 44px;
          background: #fff;
          border-radius: 100px;
          outline: none;
          text-align: center;
          font-size: 16px;
          font-weight: 900;
          color: #0363F2;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
  .headerTop{
      box-shadow: 0px 8px 20px rgba(26, 58, 129, 0.1);
    .nav-item {
        color: #333333 !important;
    }
    .now{
           background: #0363F2 !important;
           color: #fff !important;
     }
  }
</style>
