<template>
    <div class="notice">
        <div>
            <img src="../../../../assets/images/notice/ic_notice.png" alt="" />
            <p>
                云起链客SCRM系统由EWEISHOP团队倾力研发，点击微信咨询，扫码添加专属客服了解更多功能~
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Index",
};
</script>
<style lang="scss">
.notice {
    background: #f7f9ff;
    div {
        height: 60px;
        font-size: 18px;
        color: #142037;
        display: flex;
        align-items: center;
        width: 1200px;
        margin: 0 auto;
        p {
            margin-left: 40px;
        }
    }
}
</style>
