import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index'
import Scrm from '@/views/activity/scrm'
import index from '@/views/activity/618'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
    },
    {
        path: '/activity/scrm',
        name: 'Scrm',
        component: Scrm,
    },
    {
        path: '/activity/618',
        name: '618',
        component: index,
    },

]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
})

export default router
