const Mixin = {
    methods: {
        handleButton() {
            this.$store.commit('setTryout', true)
        },
        handleClose() {
            this.$store.commit('setTryout', false)
        },
        handleLink(e) {
            let isAnchor = e.slice(0,1) == '#'
            let id = e.slice(1)
            if (isAnchor) {
                document.getElementById(id).scrollIntoView({ block: 'start', behavior: 'smooth' })
            } else {
                window.open(e,"_blank")
            }
           
        }
    }
}

export default Mixin;