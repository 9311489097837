
<template>
    <div class="tryout" v-if="isTryout">
        <div class="tryout-content">
            <div class="tryout-content-code">
                <img src="~img/index/code2.png" alt="" />
            </div>
        </div>
        <div class="tryout-mask" @click="handleClose"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    components: {},
    computed: {
        isTryout(){
            return this.$store.getters.getTryout
        }
    },
    methods: {},
};
</script>
<style lang='scss' scoped>
.tryout {
    &-mask {
        height: 100vh;
        width: 100vw;
        position: fixed;
        background: rgba(0, 0, 0, 0.4);
        top: 0;
        z-index: 1000;
    }
    &-content {
        position: fixed;
        left: 50%;
        top: 50%;
        margin-top: -180px;
        margin-left: -215px;
        z-index: 1001;
        &-code {
            height: 433px;
            width: 360px;
            background-image: url(~img/index/tryout.png);
            background-size: 100% 100%;
            position: relative;
            img {
                width: 200px;
                height: 200px;
                position: absolute;
                top: 125px;
                left: 80px;
            }
        }
    }
}
</style>