import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isTryout:false
    },
    mutations: {
        setTryout(state, data) {
            state.isTryout = data;
        },
    },
    actions: {
    },
    getters: {
        getTryout: state => state.isTryout,
    },
    modules: {
    },
})
