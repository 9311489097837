<template>
  <div class="introduce">
      <div class="introduce-item" v-for="(item,index) in list" :key="index">
          <img :src="item.src" alt="">
          <div>
              <p class="title">{{item.title}}</p>
              <p  class="subtitle">
                  <span v-for="(item2, index2) in item.subtitle" :key="index2" >{{item2}}</span>
              </p>
          </div>

      </div>

  </div>
</template>

<script>
import * as img1 from 'img/introduce/img1.png';
import * as img2 from 'img/introduce/img2.png';
import * as img3 from 'img/introduce/img3.png';
import * as img4 from 'img/introduce/img4.png';
export default {
  data () {
    return {
        list:[
            {
                title:'独立部署更省钱',
                subtitle:['私有化部署系统','独立品牌运作'],
                src:img1
            },
            {
                title:'每周更新强输出',
                subtitle:['紧跟时代变化','持续更新迭代'],
                src:img2
            },
            {
                title:'专业团队有保障',
                subtitle:['365天x24小时','团队运维保障'],
                src:img3
            },
            {
                title:'售后保障无担忧',
                subtitle:['遵守服务秩序','让您无后顾之忧'],
                src:img4
            }
            
        ]
    };
  },
  components: {},
  computed: {},
  methods: {}
}
</script>
<style lang='scss' scoped>
.introduce{
    width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    padding:90px 0;
    display: flex;
    flex-wrap: wrap;
    opacity: 0;
    &-item{
        margin:15px 30px;
        width: 540px;
        height: 146px;
        background: #FFFFFF;
        box-shadow: 0px 0px 40px rgba(26, 58, 129, 0.1);
        border-radius: 10px;
        padding:36px 57px 0;
        box-sizing: border-box;
        display:flex;
        img{
            height:50px;
            width: 50px;
            margin:10px 36px 0 0;
        }
        .title{
            color: #142037;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            padding-bottom:12px;
        }
        .subtitle{
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 28px;
            color: #142037;
            & span:nth-child(1){
                margin-right: 20px;
            }
        }
    }
}
  .animated{
      opacity: 1;
  }
</style>