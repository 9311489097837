<template>
    <div class="scrm">
        <img src="../../assets/images/scrm/scrm-1.png" class="img-bg" />
        <div class="scrm-tab">
            <img src="../../assets/images/scrm/2-1.png" class="tab1" v-if="isTab"/>
            <img src="../../assets/images/scrm/2-2.png" class="tab2" v-else/>
            <div class="tab-item-left" @click="handleClick('tab1');"></div>
            <div class="tab-item-right" @click="handleClick('tab2');"></div>
        </div>
        <img src="../../assets/images/scrm/scrm-2.png" class="img-bg" />
    </div>
</template>
<script>
export default {
    data() {
        return {
            isTab:true
        };
    },
    components: {},
    computed: {},
    methods: {
        handleClick(e){
            if(e==='tab1'){
                this.isTab = true
            }else{
                this.isTab = false
            }

        }
    },
};
</script>
<style lang='scss' scoped>
.scrm {
    font-size: 0;
    position: absolute;
    width: 100%;
    z-index:5000;
}

.scrm .img-bg {
    width: 100%;
}
.scrm-tab {
    position: relative;
    text-align: center;
    background: #240188;
}
.scrm-tab img {
    width: 57.5%;
}
.tab-item-left,
.tab-item-right {
    width: 29%;
    height: 11%;
    position: absolute;
    top: 0;
    cursor: pointer;
}
.tab-item-left {
    left: 21%;
}
.tab-item-right {
    right: 21%;
}

</style>