<template>
  <div class="utils">
    <div class="box">
      <h2 class="title">专业的私域流量运营工具</h2>
      <ul class="list">
        <li v-for="(item, index) in list" :key="index" class="item utils-item" :class="{ 'item-four': (index + 1) % 4 === 0 }">
          <img class="item-icon" :src="item.icon" alt="">
          <p class="item-title">{{ item.title }}</p>
          <p class="item-des">{{ item.des }}</p>
        </li>
      </ul>
      <button class="all" @click="handleButton">了解更多</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Index',
  data() {
    return {
      list: [
        { icon: require('../../../../assets/images/utils/customer.png'), title: '客户画像', des: '完善的客户画像，掌握客户真实需求' },
        { icon: require('../../../../assets/images/utils/fen.png'), title: '客户行为分析', des: '追踪客户行为轨迹，挖掘客户成交意向' },
        { icon: require('../../../../assets/images/utils/grouchat.png'), title: '群管理', des: '客户群统一管理，及时掌握客户变动' },
        { icon: require('../../../../assets/images/utils/ying.png'), title: '营销拓客', des: '突破流量瓶颈，低成本轻松私域获客' },
        { icon: require('../../../../assets/images/utils/source.png'), title: '话术素材', des: '统一的话术管理，一键轻松快速回复' },
        { icon: require('../../../../assets/images/utils/channel.png'), title: '群发助手', des: '精准筛选群发客户，支持定时发送' },
        { icon: require('../../../../assets/images/utils/welcome.png'), title: '欢迎语', des: '个性化专属欢迎语，提升专业形象' },
        { icon: require('../../../../assets/images/utils/cei.png'), title: '聊天侧边栏增强', des: '企微侧边栏功能增强，手机端轻松管理客户' },
      ],
    }
  },
}
</script>

<style lang="scss">
  .utils {
    height: 1013px;
    padding: 80px 0;
    box-sizing: border-box;
    background: #fff;
    .box {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;;
      align-items: center;
      .title {
        font-size: 32px;
        line-height: 45px;
        color: #142037;
        font-weight: 900;
        margin-bottom: 80px;
      }
      .list {
        display: flex;
        flex-wrap: wrap;
        .item {
          width: 280px;
          height: 280px;
          padding-left: 40px;
          box-sizing: border-box;
          margin-right: 25px;
          background: #FFFFFF;
          box-shadow: 0px 0px 40px rgba(26, 58, 129, 0.1);
          border-radius: 20px;
          margin-bottom: 28px;
          &.item-four {
            margin-right: 0;
          }
          .item-icon {
            width: 60px;
            height: 60px;
            margin-top: 40px;
            margin-bottom: 30px;
          }
          .item-title {
            font-size: 24px;
            line-height: 34px;
            font-weight: bold;
            color: #142037;
            padding-bottom: 20px;
          }
          .item-des {
            width: 200px;
            font-size: 16px;
            line-height: 28px;
            color: #142037;
          }
        }
      }
      .all {
        width: 220px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 18px;
        color: #fff;
        font-weight: bold;
        margin-top: 52px;
        background: #3860F4;
        border-radius: 100px;
        border: none;
        cursor: pointer;
        outline: none;
      }
    }
  }
</style>
