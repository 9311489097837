<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
    <float-service />
  </div>
</template>

<script>
import Header from '@/components/header'
import Footer from '@/components/footer'
import FloatService from '@/components/float'
export default {
  name: 'App',
  components: {
    Header,
    Footer,
    FloatService
  },
  created(){
      var sUserAgent = navigator.userAgent.toLowerCase();
      var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
      var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
      var bIsMidp = sUserAgent.match(/midp/i) == "midp";
      var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
      var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
      var bIsAndroid = sUserAgent.match(/android/i) == "android";
      var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
      var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
      if (bIsIpad || bIsIphoneOs || bIsMidp || bIsUc7 || bIsUc || bIsAndroid || bIsCE || bIsWM) {
        //跳转移动端页面
        window.location.href= `${location.origin}/h5`
      } 
  }
}
</script>
