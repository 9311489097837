<template>
    <div class="float">
        <div class="float-content">
            <div class="float-wx">
                <img src="~img/wx/ic_wechat.png" alt="" />
                <p>微信咨询</p>
                <div class="float-code">
                    <img src="~img/index/code2.png" alt="" />
                    <p>微信扫码添加专属客服</p>
                </div>
            </div>
            <div class="float-top" @click="handleLink('#home')" v-if="isTop">
                <img src="~img/wx/ic_top.png" alt="" />
            </div>
        </div>
        <!-- <div class="float-activity" v-if="isActivity">
            <div class="float-activity-img">
                <img src="~img/index/activity.png" alt="" />
                <div class="button" @click="handleButton"></div>
                <div class="close" @click="handleClose">
                    <img src="~img/index/close.png" alt="" />
                </div>
            </div>
        </div>
        <div class="float-activity-mini" v-else @click="handleButton">
            <img src="~img/index/activity-mini.png" alt=""/>
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            isTop: false,
            isActivity: true,
        };
    },
    components: {},
    computed: {},
    mounted() {
        window.addEventListener("scroll", this.windowScroll); //监听页面滚动
        this.windowScroll();
    },
    methods: {
        windowScroll() {
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            scrollTop > 500 ? (this.isTop = true) : (this.isTop = false);
        },
        handleTop() {
            document
                .getElementById(id)
                .scrollIntoView({ block: "start", behavior: "smooth" });
        },
        handleButton() {
            window.open('http://ewei.shop/activity/scrm', "_blank");
            this.handleClose();
        },
        handleClose() {
            this.isActivity = false;
        },
    },
};
</script>
<style lang='scss' scoped>
.float {
    &-content {
        position: fixed;
        right: 0;
        top: 50%;
        z-index: 100;
    }
    &-wx {
        padding:18px;
        width:100px;
        height:100px;
        box-sizing: border-box;
        background: linear-gradient(64.63deg, #0363f2 32.17%, #0777fb 67.83%);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        color: #fff;
        text-align: center;
        box-shadow: 0px 0px 40px rgba(26, 58, 129, 0.1);
        cursor: pointer;
        img {
            width: 42px;
            height: 36px;
        }
        p {
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: #ffffff;
        }
    }
    &-top {
        margin-top: 12px;
        width: 100px;
        height: 40px;
        box-shadow: 0px 0px 40px rgba(26, 58, 129, 0.1);
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        text-align: center;
        line-height: 40px;
        background: linear-gradient(0deg, #ffffff, #ffffff);
        cursor: pointer;
    }
    &-code {
        position: fixed;
        right: 115px;
        top: 50%;
        width: 180px;
        height: 206px;
        background: #ffffff;
        border-radius: 10px;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        display: none;
        box-shadow: 0px 0px 40px rgba(26, 58, 129, 0.1);
        img {
            width: 130px;
            height: 130px;
        }
        p {
            font-weight: 900;
            font-size: 14px;
            line-height: 20px;
            color: #142037;
        }
    }
}
.float-wx:hover .float-code {
    display: block;
}
.float-activity {
    background: rgba(0, 0, 0, 0.7);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    z-index: 4000;
    &-img {
        position: fixed;
        left: 50%;
        top: 50%;
        margin-top: -269px;
        margin-left: -346px;
        z-index: 1001;
        img {
            height: 538px;
            width: 692px;
        }
        .button {
            position: fixed;
            width: 455px;
            height: 107px;
            left: 50%;
            top: 50%;
            margin-top: 161px;
            margin-left: -227px;
            cursor: pointer;
        }
        .close {
            position: fixed;
            left: 50%;
            top: 50%;
            margin-top: -352px;
            margin-left: 273px;
            img{
                width: 67px;
                height: 67px;
            }
        }
    }
}
.float-activity-mini{
        position: fixed;
        left:20px;
        top: 60%;
        z-index: 100;
        img{
            height: 170px;
            width: 220px;
        }
}
</style>